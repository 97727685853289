import React from 'react';
import { ThemeProvider } from 'styled-components';

import CoachSignUpPersonalInfo from './pages/sign-up/CoachSignUpPersonalInfo';
import CoachSignUpProfessionalInfo from './pages/sign-up/CoachSignUpProfessionalInfo';
import CoachSignUpResult from './pages/sign-up/CoachSignUpResult';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Home from './pages/home/Home';
import Layout from './components/layout/layout';
import Export from './pages/export/Export';
import Stats from './pages/stats/Stats';
import Media from './pages/media/Media';
import Calendar from './pages/calendar/Calendar';
import Program from './pages/program/Program';
import URLS from './constants/urls';
import Theme from './theme';
import CoachProfile from './pages/profile/CoachProfile';
import Trainees from './pages/trainees/Trainees';
import SignIn from './pages/sign-in/SignIn';
import { useRouting } from './hooks';
import Groups from './pages/groups/Groups';
import Chat from './pages/chat';
import NotFound from './pages/not-found/NotFound';
import ProgramTemplate from './pages/program-template/ProgramTemplate';
import TermsOfService from './pages/terms/TermsOfService';
import Templates from './pages/templates/Templates';
import VideoCall from './pages/video-call/VideoCall';
import ForgotPassword from './pages/forgot-password/ForgotPassword';
import ResetPassword from './pages/forgot-password/ResetPassword';
import Finance from './pages/finance/Finance';
import AllTrainees from './pages/all-trainees/AllTrainees';

function App() {
  const { PrivateRoute, PublicRoute, RegistrationRoute, AdminRoute } = useRouting();

  return (
    <BrowserRouter>
      <ThemeProvider theme={Theme}>
        <Switch>
          <PrivateRoute
            exact
            path={['/', URLS.home]}
            component={() => <Layout innerComponent={Home} />}
          />
          <PrivateRoute
            exact
            path={URLS.export}
            component={() => <Layout innerComponent={Export} />}
          />
          <PrivateRoute
            exact
            path={URLS.stats}
            component={() => <Layout innerComponent={Stats} />}
          />
          <AdminRoute
            exact
            path={URLS.templates}
            component={() => <Layout innerComponent={Templates} />}
          />
          <PrivateRoute
            exact
            path={URLS.media}
            component={() => <Layout innerComponent={Media} />}
          />
          <PrivateRoute
            exact
            path={URLS.calendar}
            component={() => <Layout innerComponent={Calendar} />}
          />
          <PrivateRoute
            exact
            path={URLS.trainees}
            component={() => <Layout innerComponent={Trainees} />}
          />
          <PrivateRoute
            exact
            path={URLS.all_trainees}
            component={() => <Layout innerComponent={AllTrainees} />}
          />
          <PrivateRoute
            exact
            path={URLS.groups}
            component={() => <Layout innerComponent={Groups} />}
          />
          <PrivateRoute
            exact
            path={URLS.messages}
            component={() => <Layout innerComponent={Chat} />}
          />
          <PrivateRoute
            path={URLS.settings}
            component={() => <Layout innerComponent={CoachProfile} />}
          />
          <PrivateRoute
            path={URLS.profile}
            component={() => <Layout innerComponent={CoachProfile} />}
          />
          <PrivateRoute
            exact
            path={URLS.notifications}
            component={() => <Layout innerComponent={Home} />}
          />
          <PrivateRoute
            exact
            path={[URLS.program, URLS.groupProgram]}
            component={() => <Layout innerComponent={Program} />}
          />
          <AdminRoute
            exact
            path={URLS.template}
            component={() => <Layout innerComponent={ProgramTemplate} />}
          />
          <PrivateRoute
            exact
            path={URLS.finance}
            component={() => <Layout innerComponent={Finance} />}
          />
          <PrivateRoute
            exact
            path={URLS.videoCall}
            component={() => <Layout innerComponent={VideoCall} />}
          />
          <PublicRoute
            exact
            path={[URLS.sign_up, URLS.sign_up_coach, URLS.sign_up_coach_personal]}
            component={CoachSignUpPersonalInfo}
          />
          <RegistrationRoute
            exact
            path={URLS.sign_up_coach_professional}
            component={CoachSignUpProfessionalInfo}
          />
          <PublicRoute exact path={URLS.sign_up_coach_result} component={CoachSignUpResult} />
          <PublicRoute exact path={URLS.set_password} component={ResetPassword} />
          <PublicRoute exact path={URLS.sign_in} component={SignIn} />

          <PublicRoute exact path={URLS.forgotPassword} component={ForgotPassword} />
          <PublicRoute exact path={URLS.resetPassword} component={ResetPassword} />

          <Route exact path={URLS.terms} component={TermsOfService} />

          <Route component={NotFound} />
        </Switch>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
