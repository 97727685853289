import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { meQuery } from '@witness/graphql';

import ICONS from '../../constants/icons';
import URLS from '../../constants/urls';
import ChatContext from '../../services/ChatContext';
import { SidebarLink, SidebarContainer, ChatUnreadIndicator, LinkHoverTooltip } from './components';
import { OrganizationContext } from '../../services/organizationContext';

const Sidebar = () => {
  let { pathname } = useLocation();
  const { totalUnreadCount } = useContext(ChatContext);
  const { isOrganization } = useContext(OrganizationContext);
  const { data } = useQuery(meQuery);

  return (
    <SidebarContainer>
      {(data?.getCurrentUser?.user?.role?.name === 'coach' ||
        data?.getCurrentUser?.user?.role?.name === 'org') && (
        <>
          <Link to={URLS.home}>
            <SidebarLink src={ICONS.HomeIcon} isActive={pathname === URLS.home || pathname === '/'}>
              <LinkHoverTooltip>Home</LinkHoverTooltip>
            </SidebarLink>
          </Link>
          <Link to={URLS.trainees}>
            <SidebarLink src={ICONS.TraineeIcon} isActive={pathname === URLS.trainees}>
              <LinkHoverTooltip>Programs</LinkHoverTooltip>
            </SidebarLink>
          </Link>
          <Link to={URLS.groups}>
            <SidebarLink src={ICONS.UsersIcon} isActive={pathname === URLS.groups}>
              <LinkHoverTooltip>Groups</LinkHoverTooltip>
            </SidebarLink>
          </Link>
        </>
      )}

      {/* <Link to={URLS.export}>
        <SidebarLink src={ICONS.ExportIcon} isActive={pathname === URLS.export} />
        </Link>
        <Link to={URLS.stats}>
        <SidebarLink src={ICONS.StatsIcon} isActive={pathname === URLS.stats} />
        </Link>
        <Link to={URLS.media}>
        <SidebarLink src={ICONS.MediaIcon} isActive={pathname === URLS.media} />
        </Link>
        <Link to={URLS.calendar}>
        <SidebarLink src={ICONS.CalendarIcon} isActive={pathname === URLS.calendar} />
        </Link>
        <Link to={URLS.settings}>
        <SidebarLink src={ICONS.SettingsIconSide} isActive={pathname === URLS.settings} />
      </Link> */}
      {!isOrganization && (
        <>
          <Link to={URLS.templates}>
            <SidebarLink src={ICONS.ExportIcon} isActive={pathname === URLS.templates}>
              <LinkHoverTooltip>Templates</LinkHoverTooltip>
            </SidebarLink>
          </Link>
          <Link to={URLS.messages} style={{ position: 'relative' }}>
            <SidebarLink src={ICONS.ChatIcon} isActive={pathname === URLS.messages}>
              <LinkHoverTooltip>Chat</LinkHoverTooltip>
            </SidebarLink>
            {totalUnreadCount > 0 && <ChatUnreadIndicator />}
          </Link>
          {/* <Link to={URLS.finance}>
            <SidebarLink src={ICONS.StatsIcon} isActive={pathname === URLS.finance}>
              <LinkHoverTooltip>Finance</LinkHoverTooltip>
            </SidebarLink>
          </Link> */}
        </>
      )}
          <Link to={URLS.all_trainees}>
            <SidebarLink src={ICONS.ListTrainees} isActive={pathname === URLS.all_trainees}>
              <LinkHoverTooltip>All Trainees</LinkHoverTooltip>
            </SidebarLink>
          </Link>
      <Link to={URLS.finance}>
        <SidebarLink src={ICONS.StatsIcon} isActive={pathname === URLS.finance}>
          <LinkHoverTooltip>Finance</LinkHoverTooltip>
        </SidebarLink>
      </Link>
      {/* <Link to={URLS.settings}>
        <SidebarLink src={ICONS.SettingsIconSide} isActive={pathname === URLS.settings} />
      </Link> */}
    </SidebarContainer>
  );
};

export default Sidebar;
