export const ExerciseType = {
  UPPERBODY: 'UPPERBODY',
  LOWERBODY: 'LOWERBODY',
  CORE: 'CORE',
  STRETCH: 'STRETCH',
  MULTI: 'MULTI',
  PILATESR: "PILATESR",
};

export const status = {
  LEAD: 'LEAD',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
};

export const CoachProgramStatus = {
  APPROVED: 'APPROVED',
  ONGOING: 'ONGOING',
  DECLINED: 'DECLINED',
  ENDED: 'ENDED',
};

export const RoleName = {
  coach: 'coach',
  trainee: 'trainee',
  org: 'organisation',
};

export const Gender = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};

export const FitnessLevel = {
  BEGINNER: 'BEGINNER',
  INTERMEDIATE: 'INTERMEDIATE',
  ADVANCED: 'ADVANCED',
  EXPERT: 'EXPERT',
};

export const ProgramStatus = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  CANCELLED: 'CANCELLED',
};

export const ProgramType = {
  ONETOONE: 'ONETOONE',
  GROUP: 'GROUP',
};

export const WorkoutLength = {
  halfHour: '30 Minutes',
  oneHourOrLess: '45-60 Minutes',
  oneHourOrMore: '60-90 Minutes',
};

export const ProgramLength = {
  oneMonth: 30,
  twoMonth: 60,
  threeMonth: 90,
};

export const WorkoutType = {
  WORKOUT: 'WORKOUT',
  REST: 'REST',
};

export const WorkoutStatus = {
  NEW: 'NEW',
  DONE: 'DONE',
  UNDONE: 'UNDONE',
};

export const WorkoutBlockType = {
  RFT: 'RFT',
  RNFT: 'RNFT',
  AMRAP: 'AMRAP',
  STRENGTH: 'STRENGTH',
  SUPERSETS: 'SUPER SETS',
  QUALITYSETS: 'QUALITY SETS',
  REST: 'REST',
  EMOM: 'EMOM',
  CUSTOM: 'CUSTOM',
  RUN: 'RUN',
  INTERVAL: 'INTERVALS',
  CLASSICSTRENGTH: 'CLASSICSTRENGTH',
  PILATESR: 'PILATESR',
};

export const BlockExerciseUnit = {
  REPS: 'REPS',
  SECONDS: 'SECONDS',
  METERS: 'METERS',
  MINUTES: 'MINUTES',
  KILOMETERS: 'KILOMETERS',
  CALORIES: 'CALORIES',
};

export const BlockExerciseWeightType = {
  KG: 'KG',
  PERCENTAGE: 'PERCENTAGE',
};

export const CalendarType = {
  WORKOUT: 'WORKOUT',
  REST: 'REST',
  MESSAGE: 'MESSAGE',
};

export const CoachProgramStatusUI = {
  APPROVED: 'Pending',
  ONGOING: 'In progress',
  DECLINED: 'Canceled',
  ENDED: 'Finished',
};
